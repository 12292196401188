<template>
  <div id="individuel" fluid="xl" :style="cssProps">
    <div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <div class="arianne">
      <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <span class='selected'>Config</span> <span class='decoration'>></span> Choix VMC <span class='decoration'>></span> Accessoires <span class='decoration'>></span> Données <span class='decoration'>></span> PDF</p>
    </div>
    <h3>Sélectionnez la configuration de votre chantier</h3>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="main_content">
          <b-row>
            <b-col cols="12" md="6" lg="3">
              <div class="content-block shadow-box" :class="{ 'missing' : empty_typo}">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                    <p><span>Type</span><br> de Maison</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/type-icon.png" />
                  </b-col>
                </div>
                <div class="content-form roundButton oneline row">
                  <b-col class="my-auto">
                  <!--<b-button @click.prevent="setTypologie(1)" class="typologie T1">T1</b-button>
                  <b-button @click.prevent="setTypologie(2)" class="typologie T2">T2</b-button>-->
                  <b-button @click.prevent="setTypologie(3)" class="typologie T3">T3</b-button>
                  <b-button @click.prevent="setTypologie(4)" class="typologie T4">T4</b-button>
                  <b-button @click.prevent="setTypologie(5)" class="typologie T5">T5</b-button>
                  <b-button @click.prevent="setTypologie(6)" class="typologie T6">T6</b-button>
                  <b-button @click.prevent="setTypologie(7)" class="typologie T7">T7</b-button>
                  </b-col>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <div class="content-block shadow-box" :class="{ 'missing' : empty_niveau}">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                  <p><span>Niveaux</span><br>Habitables</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/level-icon.png" />
                  </b-col>
                </div>
                <div class="row content-form roundButton oneline">
                  <b-col class="my-auto">
                  <b-button @click.prevent="setNiveau(0)" class="niveau rdc">RDC</b-button>
                  <b-button @click.prevent="setNiveau(1)" class="niveau niveau_1">RDC+1</b-button>
                  <!--<b-button @click.prevent="setNiveau(2)" class="niveau niveau_2">RDC+2</b-button>-->
                  </b-col>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <div class="content-block shadow-box" :class="{ 'missing' : empty_surface}">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                    <p><span>Surface</span><br>en m²</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/size-icon.png" />
                  </b-col>
                </div>
                <div class="row content-form roundButton oneline">
                  <b-col class="my-auto">
                  <b-button @click.prevent="setSurface('P')" class='surface surface_p'>&lt;100</b-button>
                  <b-button @click.prevent="setSurface('M')" class='surface surface_m' style="text-transform:none">100 à 150</b-button>
                  <b-button @click.prevent="setSurface('G')" class='surface surface_g' style="text-transform:none">150 à 200</b-button>
                  </b-col>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <div class="content-block shadow-box" :class="{ 'missing' : empty_forme}">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                    <p><span>Forme</span><br>du batiment</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/form-icon.png" />
                  </b-col>
                </div>
                <div class="row content-form roundButton labelline">
                  <b-col class="my-auto" style="padding:0;margin:0;">
                  <b-row style="margin-bottom:0">
                    <b-col class='carre' style="padding: 0;">
                      Carré
                    </b-col>
                    <b-col class='rectangle' style="padding: 0;">
                      Rectangle
                    </b-col>
                    <b-col class='forme_en_l' style="padding: 0;">
                      L
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col style="padding: 0;">
                      <b-button @click.prevent="setForm('C')" class='forme forme_c'><span>&nbsp;</span></b-button>
                    </b-col>
                    <b-col style="padding: 0;">
                      <b-button @click.prevent="setForm('R')" class='forme forme_r'><span>&nbsp;</span></b-button>
                    </b-col>
                    <b-col style="padding: 0;">
                      <b-button @click.prevent="setForm('L')" class='forme forme_l'><span class='l_button'><span class='head_button'>&nbsp;</span></span></b-button>
                    </b-col>
                  </b-row>
                </b-col>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" lg="3">
              <div class="content-block shadow-box" :class="{ 'missing' : empty_pt}">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                    <p><span>Pièces</span><br>techniques</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/piece-icon.png" />
                  </b-col>
                </div>
                <div class="row content-form spinner">
                  <b-col class="my-auto" style="padding:0;margin:0;">
                    <b-row class="no-gutters">
                      <b-col cols="7" style="padding-right:0;padding-left:0">
                        <label for="sdb">Salle de bain</label>
                      </b-col>
                      <b-col cols="3" style="text-align:right;">
                        <b-form-spinbutton inline id="sdb" v-model="form.sdb" min="0" max="3" size="sm" @change="setSdb" class="small-version"></b-form-spinbutton>
                      </b-col>
                    </b-row>
                    <b-row class="no-gutters">
                      <b-col cols="7" style="padding-right:0;padding-left:0">
                        <label for="wc">WC</label>
                      </b-col>
                      <b-col cols="3" style="text-align:right;">
                        <b-form-spinbutton inline id="wc" v-model="form.wc" min="0" max="3" size="sm" @change="setSdb" class="small-version"></b-form-spinbutton>
                      </b-col>
                    </b-row>
                    <b-row class="no-gutters" v-if="!$islm">
                      <b-col cols="7" style="padding-right:0;padding-left:0">
                        <label for="sdb_wc">Salle de bain + WC</label>
                      </b-col>
                      <b-col cols="3" style="text-align:right;">
                        <b-form-spinbutton inline id="sdb_wc" v-model="form.sdb_wc" min="0" max="3" size="sm" @change="setSdb" class="small-version"></b-form-spinbutton>
                      </b-col>
                    </b-row>
                    <b-row class="no-gutters">
                      <b-col cols="7" style="padding-right:0;padding-left:0">
                        <label for="eau">Salle d'eau (cellier, buanderie...)</label>
                      </b-col>
                      <b-col cols="3" style="text-align:right;">
                        <b-form-spinbutton inline id="eau" v-model="form.eau" min="0" max="3" size="sm" @change="setSdb" class="small-version"></b-form-spinbutton>
                      </b-col>
                    </b-row>
                  </b-col>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <div class="content-block shadow-box" :class="{ 'missing' : empty_empla}">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                    <p><span>Emplacement</span><br>de la VMC</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/vmc-icon.png" />
                  </b-col>
                </div>
                <div class="row content-form checkBox">
                  <b-col class="my-auto" style="padding:0;margin:0;">
                    <b-row>
                      <b-col>
                        <b-button @click.prevent="setEmplacement('C')" class='emplacement emplacement_c'>Centrée</b-button>
                      </b-col>
                      <b-col>
                        <b-button @click.prevent="setEmplacement('E')" class='emplacement emplacement_e'>Excentrée</b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <div class="content-block shadow-box" :class="{ 'missing' : empty_reglem}">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                    <p><span>Exigences</span><br>du batiment</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/reglementation-icon.png" />
                  </b-col>
                </div>
                <div class="row content-form checkBox">
                  <b-col class="my-auto" style="padding:0;margin:0;">
                    <b-row>
                      <b-col>
                        <b-button @click.prevent="setReglementation('E')" class='reglementation reglementation_e'>Existant<br> &nbsp;</b-button>
                      </b-col>
                      <b-col>
                        <b-button @click.prevent="setReglementation('N')" class='reglementation reglementation_n' style="font-size: 0.8em;">Construction<br>Neuve</b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </div>
            </div>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <div class="content-block shadow-box">
                <div class='row header-form align-items-center'>
                  <b-col cols="8">
                    <p><span>Type</span><br>d'entrée d'air</p>
                  </b-col>
                  <b-col cols="4">
                    <b-img src="../assets/entree-icon.png" />
                  </b-col>
                </div>
                <div class="row">
                  <b-col>
                    <p class="colorText">Pour VMC Simple Flux</p>
                  </b-col>
                </div>
                <div class="row content-form switchBox">
                  <b-col class="my-auto" style="padding:0;margin:0;">
                    <b-row class="no-gutters">
                      <b-col cols="3">
                        <b-form-checkbox id="eca_switch" v-model="air" switch @input="changeAir"></b-form-checkbox>
                      </b-col>
                      <b-col cols="9" class="switch-label air active">
                        <label for="eca_switch"><span v-if="!$islm">Entrée d'air acoustique</span><span v-if="$islm">Entrée d'air standard</span></label>
                      </b-col>
                    </b-row>
                    <b-row class="no-gutters">
                      <b-col cols="3">
                        <b-form-checkbox id="rallonge_switch" v-model="rallonge" switch @input="changeRallonge"></b-form-checkbox>
                      </b-col>
                      <b-col cols="9" class="switch-label rallonge">
                        <label for="rallonge_switch"><span v-if="!$islm">Entrée d'air acoustique avec rallonge</span><span v-if="$islm">Entrée d'air acoustique</span></label>
                      </b-col>
                    </b-row>
                  </b-col>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="footer align-items-between no-gutters">
            <b-col md="2" cols="6" order="2" order-md="1" style="text-align:left;">
              <b-button @click="$router.go(-1)">
                <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
              </b-button>
            </b-col>
            <b-col md="8" cols="12" order="1" order-md="2" class="content inner-shadow-box">
              <div class="content-block" style="background:none;">
                <p>Au dela de 7 pièces, de 200m², ou de 2 niveaux habitables, <strong>contactez votre conseiller</strong>.</p>
                <p>Pour une ventilation double flux, S&amp;P France préconise l'installation en local technique chauffé / isolé.</p>
              </div>
            </b-col>
            <b-col md="2" cols="6" order="3" order-md="3" style="text-align:right;">
              <b-button type="submit" class="next">
                <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
              </b-button>
            </b-col>
          </b-row>
    </b-form>
    <b-card class="mt-3" v-if="debugging" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'Individuel',
  data() {
      return {
        justChangeNeuf:false,
        justChangeExistant:false,
        air: true,
        changeECAR:false,
        debugging:false,
        validate: false,
        reglementation: { existant:false, neuf:false},
        surface: [{ text: 'Surface', value: null },{ text: '100m²', value: '4' }, { text: '100 à 150m²', value: '6' }, { text: '150 à 200m²', value: '8' }],
        niveau: [{ text: 'Niveau Habitable', value: null },{ text: 'Rez de chaussée', value: '1' }, { text: '+1 Etage', value: '1.2' }, { text: '+2 Etage', value: '1.6' }],
        typologie: [{ text: 'Typologie', value: null },
        { text: 'T1', value: 1 },{ text: 'T2', value: 2 },{ text: 'T3', value: 3 },
        { text: 'T4', value: 4 },{ text: 'T5', value: 5 },{ text: 'T6', value: 6 },{ text: 'T7', value: 7 }],
        forme: [{ text: 'Forme', value: null },{ text: 'Carré', value: '1' },{ text: 'Rectangle', value: '1.2' },{ text: 'L', value: '1.4' }],
        emplacement: { centree:false, excentree:false},
        rallonge:false,
        show: true,
        items: [
          {
            text: 'Accueil',
            href: '/'
          },
          {
            text: 'Individuel',
            active: true
          }
        ],
        empty_typo:false,
        empty_forme:false,
        empty_pt:false,
        empty_reglem:false,
        empty_empla:false,
        empty_surface:false,
        empty_niveau:false,
      }
    },
    methods: {
      getImgUrl(img) {
        return require(this.$css_path+img);
      },
      onSubmit(evt) {

        evt.preventDefault();
        if(this.validate){
          this.form.type = "individuel";
          this.$store.dispatch('devis/setRequest',this.form);
          const baseURI = this.$api_url+'products'
          this.$http.post(baseURI,{"request":this.form})
          .then((result) => {
            console.log("saved");
            console.log(this.$store.state.devis.devis['request']);
            this.$store.dispatch('products/setProducts',result.data);
            this.$router.push('list');
          });
        }else{
          this.empty_pt = (this.form.sdb==0 && this.form.sdb_wc==0 && this.form.wc==0 && this.form.eau==0);
          this.empty_typo = (this.form.typologie==null);
          this.empty_reglem = (this.form.reglementation==null);
          this.empty_empla = (this.form.emplacement==null);
          this.empty_surface = (this.form.surface==null);
          this.empty_niveau = (this.form.niveau==null);
          this.empty_forme = (this.form.forme==null);
        }
      },
      onReset(evt) {
        evt.preventDefault();
        this.$router.go(-1);
      },
      setTypologie(typologie){
        let x = document.getElementsByClassName("typologie");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(typologie){
          case 1:
            document.getElementsByClassName("T1")[0].classList.add('active');
          break;
          case 2:
            document.getElementsByClassName("T2")[0].classList.add('active');
          break;
          case 3:
            document.getElementsByClassName("T3")[0].classList.add('active');
          break;
          case 4:
            document.getElementsByClassName("T4")[0].classList.add('active');
          break;
          case 5:
            document.getElementsByClassName("T5")[0].classList.add('active');
          break;
          case 6:
            document.getElementsByClassName("T6")[0].classList.add('active');
          break;
          case 7:
            document.getElementsByClassName("T7")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.typologie = typologie;
        this.form = res;
      },
      setNiveau(niveau){
        let x = document.getElementsByClassName("niveau");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(niveau){
          case 0:
            document.getElementsByClassName("rdc")[0].classList.add('active');
          break;
          case 1:
            document.getElementsByClassName("niveau_1")[0].classList.add('active');
          break;
          case 2:
            document.getElementsByClassName("niveau_2")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.niveau = niveau;
        this.form = res;
      },
      setSurface(surface){
        let x = document.getElementsByClassName("surface");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(surface){
          case 'P':
            document.getElementsByClassName("surface_p")[0].classList.add('active');
          break;
          case 'M':
            document.getElementsByClassName("surface_m")[0].classList.add('active');
          break;
          case 'G':
            document.getElementsByClassName("surface_g")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.surface = surface;
        this.form = res;
      },
      setEmplacement(emplacement){
        let x = document.getElementsByClassName("emplacement");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(emplacement){
          case 'C':
            document.getElementsByClassName("emplacement_c")[0].classList.add('active');
          break;
          case 'E':
            document.getElementsByClassName("emplacement_e")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.emplacement = emplacement;
        this.form = res;
      },
      setReglementation(reglementation){
        let x = document.getElementsByClassName("reglementation");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(reglementation){
          case 'N':
            document.getElementsByClassName("reglementation_n")[0].classList.add('active');
          break;
          case 'E':
            document.getElementsByClassName("reglementation_e")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.reglementation = reglementation;
        this.form = res;
      },
      setForm(forme){
        let x = document.getElementsByClassName("forme");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        document.getElementsByClassName("carre")[0].classList.remove('active');
        document.getElementsByClassName("rectangle")[0].classList.remove('active');
        document.getElementsByClassName("forme_en_l")[0].classList.remove('active');
        switch(forme){
          case 'C':
            document.getElementsByClassName("forme_c")[0].classList.add('active');
            document.getElementsByClassName("carre")[0].classList.add('active');
          break;
          case 'R':
            document.getElementsByClassName("forme_r")[0].classList.add('active');
            document.getElementsByClassName("rectangle")[0].classList.add('active');
          break;
          case 'L':
            document.getElementsByClassName("forme_l")[0].classList.add('active');
            document.getElementsByClassName("forme_en_l")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.forme = forme;
        this.form = res;
      },
      setSdb(){
        console.log(this.form);
        let res = this.form;
        this.form = res;
      },
      changeRallonge(){
        if(!this.changeECAR){
          let res = this.form;
          this.changeECAR = true;
          this.air = !this.rallonge;
          res.rallonge = this.rallonge;
          if(this.rallonge){
            document.getElementsByClassName("rallonge")[0].classList.add('active');
            document.getElementsByClassName("air")[0].classList.remove('active');
          }else{
            document.getElementsByClassName("rallonge")[0].classList.remove('active');
            document.getElementsByClassName("air")[0].classList.add('active');
          }
          this.form = res;
        }else{
          this.changeECAR = false;
        }
      },
      changeAir(){
        if(!this.changeECAR){
          let res = this.form;
          this.changeECAR = true;
          this.rallonge = !this.air;
          res.rallonge = this.rallonge;
          if(this.rallonge){
            document.getElementsByClassName("rallonge")[0].classList.add('active');
            document.getElementsByClassName("air")[0].classList.remove('active');
          }else{
            document.getElementsByClassName("rallonge")[0].classList.remove('active');
            document.getElementsByClassName("air")[0].classList.add('active');
          }
          this.form = res;
        }else{
          this.changeECAR = false;
        }
      }
    },
    computed:{
      cssProps() {
          return {
            '--maincolor': this.$maincolor
          }
        },
      form: {
        get:function() {
          let state = {};
          if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['request']!=undefined){
            state = this.$store.state.devis.devis['request'];
          }else{
            state['reglementation'] = null;
            state['typologie'] = null;
            state['surface'] = null;
            state['niveau'] = null;
            state['forme'] = null;
            state['emplacement'] = null;
            state['rallonge'] = false;
            state['sdb'] = state['sdb_wc'] = state['wc'] = state['eau'] = 0;
          }
          return state;
        },
        set:function(){
          console.log(this.form);
          let validate = (this.form.sdb!=0 || this.form.sdb_wc!=0 || this.form.wc!=0 || this.form.eau!=0)
            && this.form.typologie!=null && this.form.reglementation!=null && this.form.emplacement!=null
            && this.form.surface!=null  && this.form.niveau!=null && this.form.forme!=null;
          this.validate = validate;
        }
      },
    },mounted(){
      window.scrollTo(0, 0);
      console.log(this.$css_path);
      console.log("mouted");
      if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['request']!=undefined){
        console.log("ok");
        let state = this.$store.state.devis.devis['request'];
        console.log(state);
        if(state!=undefined){
          this.validate = true;
        }
        this.changeECAR = true;
        this.rallonge=state['rallonge'];
        this.changeECAR = true;
        this.air = !this.rallonge;
        if(this.rallonge){
          document.getElementsByClassName("rallonge")[0].classList.add('active');
          document.getElementsByClassName("air")[0].classList.remove('active');
        }else{
          document.getElementsByClassName("rallonge")[0].classList.remove('active');
          document.getElementsByClassName("air")[0].classList.add('active');
        }

        switch(state['reglementation']){
          case 'E':
            document.getElementsByClassName("reglementation_e")[0].classList.add('active');
          break;
          case 'N':
            document.getElementsByClassName("reglementation_n")[0].classList.add('active');
          break;
        }

        switch(state['emplacement']){
          case 'E':
            document.getElementsByClassName("emplacement_e")[0].classList.add('active');
          break;
          case 'C':
            document.getElementsByClassName("emplacement_c")[0].classList.add('active');
          break;
        }

        switch(state['surface']){
          case 'P':
            document.getElementsByClassName("surface_p")[0].classList.add('active');
          break;
          case 'M':
            document.getElementsByClassName("surface_m")[0].classList.add('active');
          break;
          case 'G':
            document.getElementsByClassName("surface_g")[0].classList.add('active');
          break;
        }

        switch(state['forme']){
          case 'C':
            document.getElementsByClassName("forme_c")[0].classList.add('active');
          break;
          case 'R':
            document.getElementsByClassName("forme_r")[0].classList.add('active');
          break;
          case 'L':
            document.getElementsByClassName("forme_l")[0].classList.add('active');
          break;
        }

        switch(state['niveau']){
          case 0:
            document.getElementsByClassName("rdc")[0].classList.add('active');
          break;
          case 1:
            document.getElementsByClassName("niveau_1")[0].classList.add('active');
          break;
          case 2:
            document.getElementsByClassName("niveau_2")[0].classList.add('active');
          break;
        }

        switch(state['typologie']){
          case 1:
            document.getElementsByClassName("T1")[0].classList.add('active');
          break;
          case 2:
            document.getElementsByClassName("T2")[0].classList.add('active');
          break;
          case 3:
            document.getElementsByClassName("T3")[0].classList.add('active');
          break;
          case 4:
            document.getElementsByClassName("T4")[0].classList.add('active');
          break;
          case 5:
            document.getElementsByClassName("T5")[0].classList.add('active');
          break;
          case 6:
            document.getElementsByClassName("T6")[0].classList.add('active');
          break;
          case 7:
            document.getElementsByClassName("T7")[0].classList.add('active');
          break;
        }
      }
    }
}
</script>

<style lang="scss">
.header {
  border-radius:2em;
  padding-right:1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  #estimair-logo { max-width: 370px; width:85%}
  #logo-business { max-width: 75px; width:15%; margin-top: -10px;}
}

.arianne{
  margin: 1em 0;
  p{
    color: #999999;
    a,.decoration { color: black}
    .selected{
      color: var(--maincolor);
    }
  }
}

.footer{
  .content{
    border-radius: 2em;
    color: #666666;
    @media (max-width: 576px) {
        padding: 1em 1em 0.5em 1em;
    }
    padding: 1em 3em 0.5em 3em;
    line-height: 1.2em;
    text-align: center;
    font-size: 1.5em;
  }
  button{
    background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
    border-radius: 50%;
    border: 0;
    width: 13vh;
    height: 13vh;
    img{
      height:9vh;
    }
    &.next{
      background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);

      img{
        margin-left: 0.5em;
        transform: rotate(180deg);
      }
    }
  }
}

.main_content {
  .col-12 {
    padding: 0 32px;
    @media (max-width: 1300px) {
        padding:0 10px;
        margin-bottom: 1.2em;
    }

    @media (max-width: 576px) {
        padding:0;
        margin-bottom: 1.2em;
    }
    div.content-block {
      &.selected_block{
        border: 2px solid var(--maincolor);
      }
      &.missing{ border:1px solid red; }
      background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
      border-radius: 1em;
      padding: 1em 2em 1.5em 2em;
      height:100%;
      p.colorText{
        color: var(--maincolor);
        font-size: 1em;
        font-weight:normal;
      }
      .header-form {
        position: relative;
        p {
          &.colorText{
            color: var(--maincolor);
            font-size: 1em;
            font-weight:normal;
          }
          font-size: 1.5em;
          line-height: 1em;
          font-weight: bold;
          width:65%;
          text-transform: uppercase;
          color: #666666;
          text-align: left;
          z-index: 10;
          position: relative;
          span {
            color: var(--maincolor);
          }
        }
        .col-4{ text-align:right;}
        img {
          width:100%;
        }
      }
    }
  }
}
#individuel{
  //padding: 3em 3em 0;
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;

  h3{
    margin-bottom:2em;
  }
  @media (max-width: 767.98px) {
    .main_content {
      .col-12 div.content-block {
        .header-form img { width: 50%; }
      }
    }
  }
  @media (max-width: 576px) {
      padding:3em 0 0;
  }
  .main_content > .row { margin-bottom: 2em;
    @media (max-width: 576px) {
        margin-bottom: 0;
    }
  }



  .main_content {
    .col-12 div.content-block {
      .content-form{

        &.switchBox{
          margin-top: 1em;
          .switch-label{
            font-size: 1.5em;
            text-align:left;
            padding-left:15px;
            &.active{
              color:var(--maincolor);
            }
          }
        }
        text-align: center;

        .custom-control-label {
          color: #666;
          text-align: left;
          font-size: 1.5em;
        }

        .custom-switch  {
          //padding-left: 3.25rem;
          .custom-control-label::before {
              //left: -2.25rem;
              //width: 1.75rem;
              pointer-events: all;
              border-radius: 1.5rem;
              padding: 0.9rem 1.75rem;
              background: transparent !important;
              box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
              border: 0px;
              top: 0.3rem
          }

          .custom-control-label::after {
              //top: calc(.25rem + 2px);
              //left: calc(-2.25rem + 2px);
              width: calc(1.9rem - 10px);
              height: calc(1.9rem - 10px);
              background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              border-radius: 2.5rem;
              top: 0.55rem;
              left:calc(-2.25rem + 5px);
              transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }
          .custom-control-input:checked~.custom-control-label::before, .custom-control-input:disabled:checked~.custom-control-label::before{
            background: linear-gradient(-122deg, rgba(51,51,51,1) 0%, rgba(102,102,102,1) 100%) !important;
            box-shadow: none;
          }

          .custom-control-input:checked~.custom-control-label::after {
              background: white;
              transform: translateX(1.6rem);
          }
        }

        &.checkBox{
          margin-top: 2em;
          border-radius:1em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          button{
            box-shadow: none;
            background: transparent !important;
            border:0px;
            color: #666;
            margin:1em 1em;
            padding:1em;
            text-transform: none;
            width: 81%;

            &.active{
              color: var(--maincolor);
              box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
            }
          }
        }
        &.spinner{
          text-align: left;
          .row { margin-bottom: 0em;}
          .b-form-spinbutton{
            &.small-version{
              .btn.btn-sm {
                &:first-of-type{
                  margin-right:0;
                }
                &:last-of-type{
                  margin-left:0;
                }
              }
              output{
                padding:0;
              }
            }
          margin-bottom:1em;
          border-radius:2em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          .btn.btn-sm {
            padding:0;
            color: #666;
          }
        }
        }

        &.roundButton {
          &.labelline{
              .carre.active,.rectangle.active,.forme_en_l.active { color: var(--maincolor); }
              .forme_c{
                &.active span{background: var(--maincolor);}
                span{
                  width:20px;
                  height:20px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_r {
                &.active span{background: var(--maincolor);}
                span{
                  width:15px;
                  height:28px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_l {
                &.active span{
                  background: var(--maincolor);
                  span.head_button {
                    background: var(--maincolor);
                  }
                }
                span{
                  width:11px;
                  height:33px;
                  background: #666;
                  display:block;
                  position: relative;
                  margin: 0 25%;
                    span.head_button{
                    width:20px;
                    height:11px;
                    background: #666;
                    display:block;
                    position: absolute;
                    bottom:0;
                    left:0;
                  }
                }
              }
          }
          &.oneline{
            height: 55%;
            align-items: center;
            display: flex;
            justify-content: center;
            button{
              font-size:0.8em;
            }
          }
          button{
            width: 50px;
            height: 50px;
            padding: 7px 6px;
            border-radius: 50%;
            font-size: 1em;
            text-align: center;
            color: #666666;
            background: linear-gradient(122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
            border: 0px;
            box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
            &.active{
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              color: var(--maincolor);
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
</style>
